<template>
  <div>
    <base-section
      id="service-provider-account"
      space="16"
    >
      <v-container>
        <kdw-Statusbar-card
          v-model="snackbarUpdate"
          :snackbar-timeout="snackbarTimeout"
          :snackbar-message="snackbarMessage"
        />
        <v-row justify="space-between">
          <v-col
            cols="12"
            md="12"
          >
            <v-card
              class="rounded-xl mx-auto"
              max-width="1200"
              flat
            >
              <v-col
                cols="12"
                md="12"
              >
                <base-info-card
                  :title="$t('AccountSP.mainTitle')"
                />
              </v-col>
            </v-card>
            <v-card
              class="rounded-xl mx-auto"
              max-width="1200"
            >
              <v-system-bar
                color="jean"
                class="grey--text text--lighten-3"
              >
                <v-icon
                  class="ml-3 grey--text text--lighten-3"
                >
                  mdi-card-account-details-outline
                </v-icon>
                <span
                  class="font-weight-bold ml-2"
                >
                  {{ $t('AccountSP.mainTitle').toUpperCase() }}
                </span>
              </v-system-bar>

              <v-tabs
                show-arrows
              >
                <v-tab
                  id="AccountSP_tabCpyAccount"
                  class="ml-2"
                >
                  {{ $t('AccountSP.tabCpyAccount') }}
                </v-tab>
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                />
                <v-tab
                  id="AccountSP_tabAccount"
                >
                  {{ $t('AccountSP.tabAccount') }}
                </v-tab>
                <!--
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                />
                <v-tab
                  id="AccountSP_tabInvoice"
                >
                  {{ $t('AccountSP.tabInvoice') }}
                </v-tab>
                -->
                <v-tab-item
                  class="mt-3"
                >
                  <!------------  MANAGEMENT OF THE 1st TAB: COMPANY ACCOUNT DETAILS   ----------------------->
                  <v-card>
                    <v-card-text>
                      <v-form>
                        <v-row
                          no-gutters
                        >
                          <v-col
                            cols="12"
                            sm="6"
                          >
                            <v-col
                              cols="12"
                              sm="12"
                            >
                              <kdw-text-field
                                id="identity_companyname"
                                v-model="myServiceProvider.namecpy"
                                :label="$t('identity.companyname') + $t('meta.star')"
                                prepend-icon="mdi-account-circle"
                                clearable
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                            >
                              <kdw-text-field
                                id="identity_siret"
                                v-model="myServiceProvider.siret"
                                :label="$t('identity.siret') + $t('meta.star')"
                                prepend-icon="mdi-account-circle"
                                clearable
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                            >
                              <kdw-text-field
                                id="identity_rcs"
                                v-model="myServiceProvider.rcs"
                                :label="$t('identity.rcs') + $t('meta.star')"
                                prepend-icon="mdi-bank"
                                clearable
                              />
                            </v-col>
                            <v-col
                              cols="12"
                              sm="12"
                            >
                              <v-autocomplete
                                id="IDlegalStatus"
                                v-model="myServiceProvider.legalStatus"
                                :items="legalStatusListFR1"
                                item-text="value"
                                item-value="value"
                                auto-select-first
                                dense
                                prepend-icon="mdi-balance"
                                :label="$t('identity.legalStatus') + $t('meta.star')"
                              />
                            </v-col>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                          >
                            <v-container
                              fluid
                            >
                              <v-row
                                align="center"
                                align-content="center"
                                justify="center"
                              >
                                <base-img
                                  v-if="url"
                                  id="logo_display"
                                  class="rounded ml-5"
                                  max-width="200"
                                  max-height="200"
                                  :src="url"
                                  alt="Logo"
                                />
                                <base-img
                                  v-if="url === null"
                                  id="logo_display"
                                  class="rounded ml-5"
                                  max-width="200"
                                  max-height="200"
                                  :src="require('@/assets/Avatar-02.svg')"
                                  alt="LogoDefault"
                                />
                              </v-row>
                            </v-container>
                          </v-col>
                        </v-row>
                        <v-row
                          no-gutters
                        >
                          <v-col
                            cols="12"
                            sm="6"
                          >
                            <v-col>
                              <v-file-input
                                v-if="!url"
                                id="logo_file_input"
                                v-model="image"
                                :rules="rules"
                                accept="image/png, image/jpeg, image/bmp"
                                :placeholder="$t('AccountSP.pickLogo')"
                                prepend-icon="mdi-paperclip"
                                dense
                                show-size
                                @change="previewImage"
                              />
                              <v-btn
                                v-if="url"
                                dense
                                plain
                                outlined
                                color="jean"
                                min-width="144"
                                class="font-weight-bold ml-7"
                                rounded
                                @click="deleteLogoClick"
                              >
                                {{ $t('button.deleteLogo') }}
                              </v-btn>
                            </v-col>
                          </v-col>
                        </v-row>
                        <v-row
                          no-gutters
                        >
                          <v-col
                            cols="12"
                            sm="12"
                          >
                            <v-col>
                              <kdw-text-field
                                id="IDcpyaddress1"
                                v-model="myServiceProvider.addresscpy1"
                                :label="$t('identity.address1') + $t('meta.star')"
                                prepend-icon="mdi-map-marker"
                                clearable
                              />
                            </v-col>
                          </v-col>
                        </v-row>
                        <v-row
                          no-gutters
                        >
                          <v-col
                            cols="12"
                            sm="12"
                          >
                            <v-col>
                              <kdw-text-field
                                id="IDcpyaddress2"
                                v-model="myServiceProvider.addresscpy2"
                                :label="$t('identity.address2')"
                                prepend-icon="mdi-map-marker"
                                clearable
                              />
                            </v-col>
                          </v-col>
                        </v-row>
                        <v-row
                          no-gutters
                        >
                          <v-col
                            cols="12"
                            sm="3"
                          >
                            <v-col>
                              <kdw-text-field
                                id="IDcpypostcode"
                                v-model="myServiceProvider.postcodecpy"
                                :label="$t('identity.postcode') + $t('meta.star')"
                                prepend-icon="mdi-map-marker"
                                clearable
                                @input="postCodecpyChanged"
                              />
                            </v-col>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="4"
                          >
                            <v-col>
                              <v-autocomplete
                                v-if="myServiceProvider.postcodecpy.length === 5"
                                id="IDcpytownname"
                                v-model="myServiceProvider.serviceProviderPostcodecitycpyId"
                                :items="townscpy"
                                item-text="townName"
                                class="mt-3"
                                item-value="id"
                                auto-select-first
                                dense
                                prepend-icon="mdi-map-marker"
                                :label="$t('identity.city') + $t('meta.star')"
                              />
                            </v-col>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="5"
                          >
                            <v-col>
                              <v-autocomplete
                                v-if="countries.length != 0"
                                id="IDcpycountry"
                                v-model="myServiceProvider.serviceProviderCountrycpyId"
                                :items="countries"
                                class="mt-3"
                                item-text="name"
                                item-value="id"
                                auto-select-first
                                dense
                                prepend-icon="mdi-map-marker"
                                :label="$t('identity.country') + $t('meta.star')"
                              />
                            </v-col>
                          </v-col>
                        </v-row>
                        <v-row
                          no-gutters
                        >
                          <v-col
                            cols="12"
                            sm="4"
                          >
                            <v-col>
                              <kdw-vue-tel-input
                                v-model="myServiceProvider.phonecpy"
                                select-id="IDcpyphone"
                              />
                            </v-col>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="8"
                          >
                            <v-col>
                              <kdw-text-field
                                id="IDcpymail"
                                v-model="myServiceProvider.emailcpy"
                                :label="$t('identity.mail') + $t('meta.star')"
                                :rules="rulesEMail"
                                prepend-icon="mdi-email"
                                clearable
                              />
                            </v-col>
                          </v-col>
                        </v-row>
                        <v-row
                          no-gutters
                        >
                          <v-col>
                            <v-col>
                              <v-checkbox
                                id="AccountSP_siretVerified"
                                v-model="myServiceProvider.siretVerified"
                              >
                                <template
                                  #label
                                >
                                  <p
                                    class="text-justify"
                                    v-html="$t('AccountSP.siretVerified') + $t('meta.star')"
                                  />
                                </template>
                              </v-checkbox>
                            </v-col>
                          </v-col>
                        </v-row>
                        <v-row
                          no-gutters
                        >
                          <v-col>
                            <v-col>
                              <v-checkbox
                                id="IDcpyconsent"
                                v-model="myServiceProvider.consentcpy"
                              >
                                <template
                                  #label
                                >
                                  <p
                                    class="text-justify"
                                    v-html="legalSentence + $t('meta.star')"
                                  />
                                </template>
                              </v-checkbox>
                            </v-col>
                          </v-col>
                        </v-row>
                        <v-row
                          no-gutters
                          class="mr-1 mb-1"
                          justify="end"
                        >
                          <kdw-btn
                            @click="update"
                          >
                            {{ $t('button.validateQuestion') }}
                          </kdw-btn>
                        </v-row>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item
                  class="mt-3"
                >
                  <!------------  MANAGEMENT OF THE 2nd TAB: RESPONSIBLE ACCOUNT DETAILS   ----------------------->
                  <v-card>
                    <v-card-text>
                      <v-form>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="5"
                          >
                            <kdw-text-field
                              id="IDfirstname"
                              v-model="myServiceProvider.firstname"
                              :label="$t('identity.firstname') + $t('meta.star')"
                              prepend-icon="mdi-account-circle"
                              clearable
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="4"
                          >
                            <kdw-text-field
                              id="IDlastname"
                              v-model="myServiceProvider.lastname"
                              :label="$t('identity.lastname') + $t('meta.star')"
                              prepend-icon="mdi-account-circle"
                              clearable
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="3"
                          >
                            <v-tooltip
                              bottom
                            >
                              <template #activator="{ on, attrs }">
                                <v-btn
                                  dense
                                  plain
                                  outlined
                                  color="jean"
                                  min-width="144"
                                  class="font-weight-bold ml-7"
                                  rounded
                                  v-on="on"
                                  @click="copyCpyPerso"
                                >
                                  {{ $t('button.copyCpy') }}
                                  <v-icon>
                                    mdi-content-copy
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t('button.copyCpy') }}</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="12"
                          >
                            <kdw-text-field
                              id="IDaddress1"
                              v-model="myServiceProvider.address1"
                              :label="$t('identity.address1') + $t('meta.star')"
                              prepend-icon="mdi-map-marker"
                              clearable
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="12"
                          >
                            <kdw-text-field
                              id="IDaddress2"
                              v-model="myServiceProvider.address2"
                              :label="$t('identity.address2')"
                              prepend-icon="mdi-map-marker"
                              clearable
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="3"
                          >
                            <kdw-text-field
                              id="IDpostcode"
                              v-model="myServiceProvider.postcode"
                              :label="$t('identity.postcode') + $t('meta.star')"
                              prepend-icon="mdi-map-marker"
                              clearable
                              @input="postCodeChanged"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="4"
                          >
                            <v-autocomplete
                              v-if="myServiceProvider.postcode.length === 5"
                              id="IDtownname"
                              v-model="myServiceProvider.serviceProviderPostcodecityId"
                              :items="towns"
                              class="mt-3"
                              item-text="townName"
                              item-value="id"
                              auto-select-first
                              dense
                              prepend-icon="mdi-map-marker"
                              :label="$t('identity.city') + $t('meta.star')"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="5"
                          >
                            <v-autocomplete
                              v-if="countries.length != 0"
                              id="IDcountry"
                              v-model="myServiceProvider.serviceProviderCountryId"
                              :items="countries"
                              item-text="name"
                              item-value="id"
                              class="mt-3"
                              auto-select-first
                              dense
                              prepend-icon="mdi-map-marker"
                              :label="$t('identity.country') + $t('meta.star')"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="4"
                          >
                            <kdw-vue-tel-input
                              v-model="myServiceProvider.phone"
                              select-id="IDphone"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="8"
                          >
                            <kdw-text-field
                              id="IDmail"
                              v-model="myServiceProvider.email"
                              :label="$t('identity.mail') + $t('meta.star')"
                              :rules="rulesEMail"
                              prepend-icon="mdi-email"
                              clearable
                            />
                          </v-col>
                        </v-row>
                        <v-row
                          no-gutters
                        >
                          <v-checkbox
                            id="IDconsent"
                            v-model="myServiceProvider.consent"
                          >
                            <template
                              #label
                            >
                              <p
                                class="text-justify"
                                v-html="legalSentence + $t('meta.star')"
                              />
                            </template>
                          </v-checkbox>
                        </v-row>
                        <v-row
                          justify="end"
                          class="mr-1 mb-1"
                        >
                          <kdw-btn
                            @click="update"
                          >
                            {{ $t('button.validateQuestion') }}
                          </kdw-btn>
                        </v-row>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <!------------  MANAGEMENT OF THE 3rd TAB: INVOICE DETAILS   ----------------------->
                <!--
                <v-tab-item>
                  <v-card>
                    <v-card-text>
                      <v-form>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="6"
                          >
                            <kdw-text-field
                              id="identity_invoicename"
                              v-model="myServiceProvider.nameinv"
                              :label="$t('identity.companyname')"
                              prepend-icon="mdi-account-circle"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="3"
                          >
                            <base-btn
                              v-if="url !== null"
                              dense
                              @click="copyCpyInvoice"
                            >
                              {{ $t('button.copyCpy') }}
                            </base-btn>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="6"
                          >
                            <kdw-text-field
                              id="identity_siret1"
                              v-model="myServiceProvider.siretinv"
                              :label="$t('identity.siret')"
                              prepend-icon="mdi-account-circle"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                          >
                            <v-checkbox
                              id="AccountSP_siretVerified1"
                              v-model="myServiceProvider.siretVerifiedinv"
                              :label="$t('AccountSP.siretVerified')"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="12"
                          >
                            <kdw-text-field
                              id="IDinvaddress1"
                              v-model="myServiceProvider.addressinv1"
                              :label="$t('identity.address1')"
                              prepend-icon="mdi-map-marker"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="12"
                          >
                            <kdw-text-field
                              id="IDinvaddress2"
                              v-model="myServiceProvider.addressinv2"
                              :label="$t('identity.address2')"
                              prepend-icon="mdi-map-marker"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="3"
                          >
                            <kdw-text-field
                              id="IDinvpostcode"
                              v-model="myServiceProvider.postcodeinv"
                              :label="$t('identity.postcode')"
                              prepend-icon="mdi-map-marker"
                              @input="postCodeinvChanged"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                          >
                            <v-autocomplete
                              id="IDinvtownname"
                              v-model="myServiceProvider.serviceProviderPostcodecityinvId"
                              :items="townsinv"
                              item-text="townName"
                              item-value="id"
                              dense
                              prepend-icon="mdi-map-marker"
                              :label="$t('identity.city')"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="6"
                          >
                            <v-autocomplete
                              id="IDinvcountry"
                              v-if="countries.length != 0"
                              v-model="myServiceProvider.serviceProviderCountryinvId"
                              :items="countries"
                              item-text="name"
                              item-value="id"
                              dense
                              prepend-icon="mdi-map-marker"
                              :label="$t('identity.country')"
                            />
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="4"
                          >
                            <kdw-text-field
                              id="IDinvphone"
                              v-model="myServiceProvider.phoneinv"
                              :label="$t('identity.phone')"
                              prepend-icon="mdi-phone"
                            />
                          </v-col>
                          <v-col
                            cols="12"
                            sm="8"
                          >
                            <kdw-text-field
                              id="IDinvmail"
                              v-model="myServiceProvider.emailinv"
                              :label="$t('identity.mail')"
                              :rules="rulesEMail"
                              prepend-icon="mdi-email"
                            />
                          </v-col>
                        </v-row>
                        <v-row justify="end">
                          <base-btn
                            @click="update"
                          >
                            {{ $t('button.validateQuestion') }}
                          </base-btn>
                        </v-row>
                      </v-form>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                -->
              </v-tabs>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </div>
</template>

<script>
  import { Vue, Component } from 'vue-property-decorator'
  import { updateServiceProvider as updateServiceProviderMutation } from '@/graphql/mutations'
  import { getServiceProvider } from '@/graphql/queries'
  import { API, Logger, Storage } from 'aws-amplify'
  import { fetchCountries } from '@/services/Country'
  // import { createServiceProvider } from '@/services/ServiceProvider'
  import { fetchTownfromPostalCode } from '@/services/PostalCode'
  import { FILEHEADERLOGO } from '@/services/File'
  import { getUserSub } from '@/services/User'
  import { buildLegalSentenceTagBased } from '@/helpers/GenericFunctions'

  const logger = new Logger('ServiceProviderAccount')

  @Component({ name: 'ServiceProviderMyAccount' })
  class ServiceProviderMyAccount extends Vue {
    snackbarUpdate = false
    snackbarMessage = ''
    snackbarTimeout = 2000
    legalSentence = ''
    url = null
    image = null
    changeImage = false
    // old
    // newAvatar = null
    // imageURL = null
    myServiceProvider = { id: '', firstname: '', lastname: '', address1: '', address2: '', postcode: '', serviceProviderPostcodecityId: '', serviceProviderCountryId: '', phone: '', email: '', consent: false, namecpy: '', logocpy: '', siret: '', siretVerified: false, addresscpy1: '', addresscpy2: '', postcodecpy: '', serviceProviderPostcodecitycpyId: '', serviceProviderCountrycpyId: '', phonecpy: '', emailcpy: '', consentcpy: false, rcs: '', legalStatus: '' }
    // myImage = {}
    countries = []
    towns = []
    townscpy = []
    townsinv = []

    rules = [
      value => !value || value.size < 2000000 || this.$i18n.t('AccountSP.sizeLogo'),
    ]

    rulesEMail = [
      v => !!(v || '').match(/@/) || this.$i18n.t('AccountSP.mailFormat'),
    ]

    legalStatusListFR = ['', '', '', '', '', '']

    legalStatusListFR1 = [{ value: this.$i18n.t('identity.cpyFRIndividual') },
                          { value: this.$i18n.t('identity.cpyFRAutoEntrepreneur') },
                          { value: this.$i18n.t('identity.cpyFRSARL') },
                          { value: this.$i18n.t('identity.cpyFRSAS') },
                          { value: this.$i18n.t('identity.cpyFRSA') },
                          { value: this.$i18n.t('identity.cpyFRSNC') },
    ]
    /* DOES NOT WORK THIS ONE
    get legalStatusListFR () {
      return [{ value: this.$i18n.t('identity.cpyFRIndividual'), key: 0 },
              { value: this.$i18n.t('identity.cpyFRAutoEntrepreneur'), key: 1 },
              { value: this.$i18n.t('identity.cpyFRSARL'), key: 2 },
              { value: this.$i18n.t('identity.cpyFRSAS'), key: 3 },
              { value: this.$i18n.t('identity.cpyFRSA'), key: 4 },
              { value: this.$i18n.t('identity.cpyFRSNC'), key: 5 },
            ]
    }
    */

    deleteLogoClick () {
      // console.log('delete logo click')
      this.url = null
      this.image = null
    }

    // display the logo onceselected.
    previewImage () {
      this.url = URL.createObjectURL(this.image)
      // console.log(this.image)
      // console.log(this.url)
      // this.myServiceProvider.logocpy = this.image.name
      // console.log('logo company in preview image:' + this.myServiceProvider.logocpy)
      if ((this.myServiceProvider.logocpy !== '') && (this.myServiceProvider.logocpy !== this.myServiceProvider.id + FILEHEADERLOGO + this.image.name)) {
        // console.log('image modifiée')
        this.changeImage = true
      }
    }

    postCodeChanged () {
      // console.log('postCodeChanged :' + this.myServiceProvider.postcode)
      if (this.myServiceProvider.postcode.length === 5) {
        fetchTownfromPostalCode(this.myServiceProvider.postcode, this.towns)
      }
    }

    postCodecpyChanged () {
      // console.log('postCodecpyChanged :' + this.myServiceProvider.postcodecpy)
      if (this.myServiceProvider.postcodecpy.length === 5) {
        fetchTownfromPostalCode(this.myServiceProvider.postcodecpy, this.townscpy)
      }
    }

    postCodeinvChanged () {
      // console.log('postCodecpyChanged :' + this.myServiceProvider.postcodecpy)
      if (this.myServiceProvider.postcodeinv.length === 5) {
        fetchTownfromPostalCode(this.myServiceProvider.postcodeinv, this.townsinv)
      }
    }

    // click on the submit button
    update () {
      if (!this.myServiceProvider.id || !this.myServiceProvider.namecpy || !this.myServiceProvider.email) return
      this.updateServiceProviderData(this.myServiceProvider, this.image, this.changeImage, this.url)
    }

    // Before loading the form but also called during the form exit!!!!
    created () {
      // We are building the legal sentence
      buildLegalSentenceTagBased(this.$i18n.t('AccountEU.consent1'), this.$i18n.t('AccountEU.consent2'), this.$i18n.t('AccountEU.consent3'), this.$i18n.t('AccountEU.consent4'))
        .then((sentence) => {
          // console.log('sentence:')
          // console.log(sentence)
          this.legalSentence = sentence
        })
      // console.log(this.legalStatusListFR1)
      // createServiceProvider()
      this.loadServiceProviderData(this.myServiceProvider, this.towns, this.townscpy)
        .then(result => {
          // console.log('logo:' + this.myServiceProvider.logocpy)
          if (this.myServiceProvider.logocpy !== '') {
            Storage.get(this.myServiceProvider.logocpy, { level: 'public' })
              .then(result => {
                this.url = result
                // console.log('result of the url we got from the storage:')
                // console.log(result)
              })
              .catch(err => logger.error(err))
            // console.log(this.url)
          }
        })
        .catch(err => logger.error(err))
      fetchCountries(this.countries)
    }
    /*
    copyCpyInvoice () {
      if (this.myServiceProvider.namecpy !== '') {
        this.myServiceProvider.nameinv = this.myServiceProvider.namecpy
      }
      if (this.myServiceProvider.siret !== '') {
        this.myServiceProvider.siretinv = this.myServiceProvider.siret
      }
      if (this.myServiceProvider.siretVerifiedcpy !== '') {
        this.myServiceProvider.siretVerifiedinv = this.myServiceProvider.siretVerifiedcpy
      }
      if (this.myServiceProvider.addresscpy1 !== '') {
        this.myServiceProvider.addressinv1 = this.myServiceProvider.addresscpy1
      }
      if (this.myServiceProvider.addresscpy2 !== '') {
        this.myServiceProvider.addressinv2 = this.myServiceProvider.addresscpy2
      }
      if (this.myServiceProvider.postcodecpy !== '') {
        this.myServiceProvider.postcodeinv = this.myServiceProvider.postcodecpy
      }
      if ((this.myServiceProvider.serviceProviderPostcodecitycpyId !== null) && (this.myServiceProvider.serviceProviderPostcodecitycpyId !== '')) {
        this.myServiceProvider.serviceProviderPostcodecityinvId = this.myServiceProvider.serviceProviderPostcodecitycpyId
      }
      if ((this.myServiceProvider.serviceProviderCountrycpyId !== null) && (this.myServiceProvider.serviceProviderCountrycpyId !== null)) {
        this.myServiceProvider.serviceProviderCountryinvId = this.myServiceProvider.serviceProviderCountrycpyId
      }
      if (this.myServiceProvider.phonecpy !== '') {
        this.myServiceProvider.phoneinv = this.myServiceProvider.phonecpy
      }
      if (this.myServiceProvider.emailcpy !== '') {
        this.myServiceProvider.emailinv = this.myServiceProvider.emailcpy
      }
     this.alertSnackBar('AccountSP.dataCopied', 2000)
    }
    */

    copyCpyPerso () {
      // console.log('copyCpyPerso')
      if (this.myServiceProvider.addresscpy1 !== '') {
        this.myServiceProvider.address1 = this.myServiceProvider.addresscpy1
      }
      if (this.myServiceProvider.addresscpy2 !== '') {
        this.myServiceProvider.address2 = this.myServiceProvider.addresscpy2
      }
      if (this.myServiceProvider.postcodecpy !== '') {
        this.myServiceProvider.postcode = this.myServiceProvider.postcodecpy
      }
      if ((this.myServiceProvider.serviceProviderPostcodecitycpyId !== null) && (this.myServiceProvider.serviceProviderPostcodecitycpyId !== '')) {
        this.myServiceProvider.serviceProviderPostcodecityId = this.myServiceProvider.serviceProviderPostcodecitycpyId
      }
      if ((this.myServiceProvider.serviceProviderCountrycpyId !== null) && (this.myServiceProvider.serviceProviderCountrycpyId !== null)) {
        this.myServiceProvider.serviceProviderCountryId = this.myServiceProvider.serviceProviderCountrycpyId
      }
      if (this.myServiceProvider.phonecpy !== '') {
        this.myServiceProvider.phone = this.myServiceProvider.phonecpy
      }
      if (this.myServiceProvider.emailcpy !== '') {
        this.myServiceProvider.email = this.myServiceProvider.emailcpy
      }
      this.alertSnackBar('AccountSP.dataCopied', 2000)
    }

    async loadServiceProviderData (myServiceProvider, towns, townscpy) {
      // console.log('loadServiceProviderData')
      // const user = await Auth.currentAuthenticatedUser()
      try {
        // console.log('sub SP: ' + user.attributes.sub)
        const apiDataEndUser = await API.graphql({ query: getServiceProvider, variables: { id: await getUserSub() }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
        // console.log(apiDataEndUser)
        const ServiceProvider = apiDataEndUser.data.getServiceProvider
        // console.log(ServiceProvider)
        if (ServiceProvider.length === 0) {
          this.alertSnackBar('AccountSP.alertSPNoFound', 3000)
          // alert(this.$i18n.t('AccountSP.alertSPNoFound'))
          // createServiceProvider()
          return
        }
        // change the data by reference (can not pass the object)
        // ------------ Load the Company responsible data
        myServiceProvider.firstname = ServiceProvider.firstname
        myServiceProvider.lastname = ServiceProvider.lastname
        myServiceProvider.address1 = ServiceProvider.address1
        myServiceProvider.address2 = ServiceProvider.address2
        myServiceProvider.postcode = ServiceProvider.postcode
        if (ServiceProvider.postcodecity === null) {
          myServiceProvider.serviceProviderPostcodecityId = ''
        } else {
          myServiceProvider.serviceProviderPostcodecityId = ServiceProvider.postcodecity.id
        }
        if (ServiceProvider.country === null) {
          myServiceProvider.serviceProviderCountryId = ''
        } else {
          myServiceProvider.serviceProviderCountryId = ServiceProvider.country.id
        }
        myServiceProvider.phone = ServiceProvider.phone
        myServiceProvider.email = ServiceProvider.email
        myServiceProvider.consent = ServiceProvider.consent
        myServiceProvider.id = ServiceProvider.id
        myServiceProvider.rcs = ServiceProvider.rcs
        myServiceProvider.legalStatus = ServiceProvider.legalStatus
        // ------------ Load the Company data
        myServiceProvider.namecpy = ServiceProvider.namecpy
        myServiceProvider.siret = ServiceProvider.siret
        myServiceProvider.siretVerified = ServiceProvider.siretVerified
        myServiceProvider.addresscpy1 = ServiceProvider.addresscpy1
        myServiceProvider.addresscpy2 = ServiceProvider.addresscpy2
        myServiceProvider.postcodecpy = ServiceProvider.postcodecpy
        myServiceProvider.logocpy = ServiceProvider.logocpy
        if (ServiceProvider.postcodecitycpy === null) {
          myServiceProvider.serviceProviderPostcodecitycpyId = ''
        } else {
          myServiceProvider.serviceProviderPostcodecitycpyId = ServiceProvider.postcodecitycpy.id
        }
        if (ServiceProvider.countrycpy === null) {
          myServiceProvider.serviceProviderCountrycpyId = ''
        } else {
          myServiceProvider.serviceProviderCountrycpyId = ServiceProvider.countrycpy.id
        }
        myServiceProvider.phonecpy = ServiceProvider.phonecpy
        myServiceProvider.emailcpy = ServiceProvider.emailcpy
        myServiceProvider.consentcpy = ServiceProvider.consentcpy
        if (ServiceProvider.postcode.length === 5) {
          // console.log(towns)
          await fetchTownfromPostalCode(myServiceProvider.postcode, towns)
        }
        if (ServiceProvider.postcodecpy.length === 5) {
          // console.log(townscpy)
          await fetchTownfromPostalCode(myServiceProvider.postcodecpy, townscpy)
        }
        // ------------ Load the invoice data
        /*
        myServiceProvider.nameinv = ServiceProvider.nameinv
        myServiceProvider.siretinv = ServiceProvider.siretinv
        myServiceProvider.siretVerifiedinv = ServiceProvider.siretVerifiedinv
        myServiceProvider.addressinv1 = ServiceProvider.addressinv1
        myServiceProvider.addressinv2 = ServiceProvider.addressinv2
        myServiceProvider.postcodeinv = ServiceProvider.postcodeinv
        if (ServiceProvider.postcodecityinv === null) {
          myServiceProvider.serviceProviderPostcodecityinvId = ''
        } else {
          myServiceProvider.serviceProviderPostcodecityinvId = ServiceProvider.postcodecityinv.id
        }
        if (ServiceProvider.countryinv === null) {
          myServiceProvider.serviceProviderCountryinvId = ''
        } else {
          myServiceProvider.serviceProviderCountryinvId = ServiceProvider.countryinv.id
        }
        myServiceProvider.phoneinv = ServiceProvider.phoneinv
        myServiceProvider.emailinv = ServiceProvider.emailinv
        if (ServiceProvider.postcodeinv.length === 5) {
          // console.log(townscpy)
          await fetchTownfromPostalCode(myServiceProvider.postcodeinv, townscpy)
        }
        */
        // console.log('myServiceProvider:')
        // console.log(myServiceProvider)
      } catch (err) {
        logger.error(err)
        this.alertSnackBar('AccountSP.alertSPLoadDataIssue', -1)
        // alert(this.$i18n.t('AccountSP.alertSPLoadDataIssue'))
      }
    }

    alertSnackBar (messagekey, snackbarTimeout) {
      this.snackbarMessage = this.$i18n.t(messagekey)
      this.snackbarUpdate = true
      this.snackbarTimeout = snackbarTimeout
    }

    async updateServiceProviderData (ServiceProviderToUpdate, file, changeImage, url) {
      try {
        // save the file logo in the different cases.
        if ((ServiceProviderToUpdate.logocpy === '') && (file === null)) {
          // Cas sans logo
          logger.info('Cas de base sans logo')
          console.log('phonecpy', ServiceProviderToUpdate.phonecpy)
          await API.graphql({ query: updateServiceProviderMutation, variables: { input: ServiceProviderToUpdate }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
          this.alertSnackBar('AccountSP.alertSPSuccessUpdate', 2000)
          // alert(this.$i18n.t('AccountSP.alertSPSuccessUpdate'))
          return
        }
        if ((ServiceProviderToUpdate.logocpy === '') && (file !== null)) {
          // A logo image have been added
          logger.info('A logo image have been added')
          await Storage.put(ServiceProviderToUpdate.id + FILEHEADERLOGO + file.name, file, { level: 'public' })
          ServiceProviderToUpdate.logocpy = ServiceProviderToUpdate.id + FILEHEADERLOGO + file.name
          logger.info('filename logo:' + ServiceProviderToUpdate.logocpy)
          API.graphql({ query: updateServiceProviderMutation, variables: { input: ServiceProviderToUpdate }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
          // alert(this.$i18n.t('AccountSP.alertSPSuccessUpdate'))
          this.alertSnackBar('AccountSP.alertSPSuccessUpdate', 2000)
          return true
        }
        if ((ServiceProviderToUpdate.logocpy !== '') && (file === null) && (url === null)) {
          logger.info('Case logo is removed')
          // Cas du delete du logo
          await Storage.remove(ServiceProviderToUpdate.logocpy, { level: 'public' })
          ServiceProviderToUpdate.logocpy = ''
          // console.log('filename logo:' + ServiceProviderToUpdate.logocpy)
          API.graphql({ query: updateServiceProviderMutation, variables: { input: ServiceProviderToUpdate }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
          // alert(this.$i18n.t('AccountSP.alertSPSuccessUpdate'))
          this.alertSnackBar('AccountSP.alertSPSuccessUpdate', 2000)
          return true
        }
        if ((ServiceProviderToUpdate.logocpy !== '') && (file !== null)) {
          if (changeImage === true) {
            logger.info('Case logo is changed')
            // Cas de changement de logo
            // 1. we delete the old logo
            await Storage.remove(ServiceProviderToUpdate.logocpy, { level: 'public' })
            // console.log(result)
            // console.log('logo deleted')
            ServiceProviderToUpdate.logocpy = ''
            // console.log('filename logo:' + ServiceProviderToUpdate.logocpy)
            API.graphql({ query: updateServiceProviderMutation, variables: { input: ServiceProviderToUpdate }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
            // 2. We add the new logo
            await Storage.put(ServiceProviderToUpdate.id + FILEHEADERLOGO + file.name, file, { level: 'public' })
            // console.log(result)
            // console.log('logo uploaded')
            ServiceProviderToUpdate.logocpy = ServiceProviderToUpdate.id + FILEHEADERLOGO + file.name
            // console.log('filename logo:' + ServiceProviderToUpdate.logocpy)
            API.graphql({ query: updateServiceProviderMutation, variables: { input: ServiceProviderToUpdate }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
            this.alertSnackBar('AccountSP.alertSPSuccessUpdate', 2000)
            // alert(this.$i18n.t('AccountSP.alertSPSuccessUpdate'))
            return true
          }
        }
        // Cas de pas de changement de logo
        logger.info('Cas de sans chgt de logo')
        API.graphql({ query: updateServiceProviderMutation, variables: { input: ServiceProviderToUpdate }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
        this.alertSnackBar('AccountSP.alertSPSuccessUpdate', 2000)
        // alert(this.$i18n.t('AccountSP.alertSPSuccessUpdate'))
      } catch (err) {
        logger.error(err)
        this.alertSnackBar('AccountSP.alertSPFailedUpdate', -1)
        // alert(this.$i18n.t('AccountSP.alertSPFailedUpdate'))
      }
    }
  }

  export default ServiceProviderMyAccount
</script>
